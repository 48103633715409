<template>
  <div class="supplier-questionnaire">
    <div class="container">
      <div class="page-header">
        <a href="/" class="page-main-link">{{$t('main')}}</a>
        <a href="/tenants" class="page-tenant-link">{{$t('suppliersCorp')}}</a>
        <a class="page-current-link">{{$t('fillForm')}}</a>
      </div>

      <h1 class="title">{{$t('formTitle')}}</h1>
      <span class="subtitle"
        >{{$t('formContent')}}</span
      >

      <supplierQuestionnaireForm />
    </div>
  </div>
</template>

<script>
export default {
  name: "SupplierQuestionnaire",
  components: {
    SupplierQuestionnaireForm: () =>
      import(
        "@/components/pages/supplier-questionnaire/SupplierQuestionnaireForm.vue"
      ),
  },
};
</script>
